body.tags-resizing {
    cursor:ew-resize;
}

#{$namespace} {
    min-height:100%;
    font-family:$baseFont;
    font-size:$baseFontSize;
    line-height:1.6;
    color:$textColor;
    background:$contentBg;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
    display:flex;
    flex-direction:column;
    * {
        box-sizing:border-box;
    }
    .ng-tags-container {
        height:100%;
        width:100%;
        max-width:1600px;
        margin:0 auto;
        display:flex;
        flex:1;
        align-items:stretch;
    }
    .tags-sidebar {
        width:$sidebarWidth;
        background:$sidebarBg;
        .jstree-ng-tags {
            padding:$gutter;
            overflow-x:hidden;
        }
    }
    .tags-content {
        flex:1;
        padding:$gutter;
        overflow-x:auto;
    }

    /* messages */
    .tags-message {
        margin:2em 0 3em;
        padding:$gutter;
        background:hsl(205, 30, 92);
        &.tags-message-success {
            background:hsl(160, 80, 80);
        }
        &.tags-message-error {
            background:hsl(0, 100, 90);
        }
    }

    /* header */
    .ng-tags-header {
        background:hsl(0, 0, 30);
        .ng-tags-logo {
            height:50px;
            width:$sidebarWidth;
            background:hsl(0, 0, 20) url(../images/tags-logo-full.svg) no-repeat center center;
            background-size:auto 60%;
        }
    }
}
.layout-column.main-column {
    background:#f5f5f5;
}
